import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 92 46" {...props}>
      <path
        d="M57.8,46H14c-7.8,0-13-4.1-13.8-11l0-0.1C0.1,33.7,0,32.5,0,31.3V14.5c0-1.4,0.1-2.8,0.3-4.2l0.1-0.6C1.7,3.7,6.9,0,14,0h64
	c7.1,0,12.3,3.7,13.5,9.7l0.1,0.6c0.2,1.4,0.3,2.8,0.3,4.2v16.8c0,1.2-0.1,2.4-0.2,3.6l0,0.1C90.9,41.8,85.8,46,78,46h-2.3l-0.8,0
	h-1.9c-0.4,0-0.7-0.3-0.7-0.7c0,0,0,0,0,0v-2.6c0-0.4,0.3-0.7,0.7-0.7h0.8c0.6,0,1.1,0,1.7,0l1.4,0c6.9,0,10.9-4.2,10.9-10.5v-17
	c0-6.3-3.9-10.3-10.9-10.3H15.1C8.1,4.1,4.2,8.2,4.2,14.5v17c0,6.2,4,10.5,10.9,10.5h41.8V18.1c0-0.4,0.3-0.7,0.7-0.7h2.7
	c0.4,0,0.7,0.3,0.7,0.7v27.2c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0L57.8,46L57.8,46z M42.6,34.4c-2.6,0-4.3-1.9-4.3-4.6V12.2
	c0-0.4,0.3-0.7,0.7-0.7h2.7c0.4,0,0.7,0.3,0.7,0.7v18.4h1.3c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v2.3
	c0,0.4-0.3,0.7-0.7,0.7L42.6,34.4z M51.9,34.4c-2.6,0-4.3-1.8-4.3-4.6V19.5c0-0.4,0.3-0.7,0.7-0.7H51c0.4,0,0.7,0.3,0.7,0.7v11.1H53
	c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3v2.3c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0L51.9,34.4L51.9,34.4z
	 M34.5,34.3h-3.1c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1l0,0c0,0,0,0,0,0l-9.5-10.5c-0.9-1.1-0.8-2.8,0.2-3.8
	l9.4-8c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0h3.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1
	c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0.1-0.1,0.2-0.1,0.2l-10,8.9l10.1,11.5c0.1,0.1,0.2,0.3,0.2,0.4v0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0
	c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0C34.7,34.3,34.6,34.3,34.5,34.3L34.5,34.3L34.5,34.3z
	 M14.6,33.9c0-0.1-0.1-0.2-0.1-0.3V12.2c0-0.1,0-0.2,0.1-0.3l0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0.1-0.1c0,0,0.1-0.1,0.2-0.1
	c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3-0.1h2.7c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0,0,0v21.4c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0h-2.7
	C15,34.3,14.7,34.1,14.6,33.9L14.6,33.9z M68.1,32.1h-4.4c-0.4,0-0.7-0.3-0.7-0.7l0,0v-2.6c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
	c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0s0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0h6.2c2.5,0,3.7-1.1,3.7-3.3
	v-0.1c0-2.1-1.2-3.2-3.7-3.2h-6.2c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c0-0.1-0.1-0.2-0.1-0.3v-2.6c0-0.2,0.1-0.4,0.2-0.5
	c0.1-0.1,0.3-0.2,0.5-0.2h6.6c3.9,0,6.6,2.2,7,5.7c0.2,1.3,0.1,2.5-0.1,3.8l0,0.1c0,0,0,0.1,0,0.1c-0.6,3.1-3.2,5-6.8,5L68.1,32.1z
	 M51,15.5h-2.7c-0.3,0-0.6-0.2-0.7-0.5c0-0.1,0-0.2-0.1-0.2v-2.6c0-0.4,0.3-0.7,0.7-0.7c0,0,0,0,0,0H51c0.3,0,0.6,0.2,0.7,0.4
	c0,0.1,0.1,0.2,0.1,0.3v2.6c0,0.1,0,0.2-0.1,0.3C51.5,15.4,51.3,15.5,51,15.5L51,15.5z"
      />
    </Svg>
  )
}

export default Icon

import React from 'react'
// import { useTranslation } from 'contexts/Localization'
import DatePicker, { DatePickerProps } from './DatePicker'

const TimePicker: React.FC<DatePickerProps> = (props) => {
  //   const { t } = useTranslation()

  return (
    <DatePicker
      required
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="ppp"
      {...props}
    />
  )
}

export default TimePicker

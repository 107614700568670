import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1307.63 1307.63" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="987.24"
          y1="32.96"
          x2="980.28"
          y2="1180.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#81897d" />
          <stop offset="1" stopColor="#62685b" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="659.29"
          y1="30.98"
          x2="652.33"
          y2="1178.63"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="683.28"
          y1="31.12"
          x2="676.32"
          y2="1178.78"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="319.84"
          y1="28.92"
          x2="312.88"
          y2="1176.58"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <path
        className="cls-1"
        style={{ fill: 'url(#linear-gradient)' }}
        d="M1265.08,784.31q-10.51,36.63-21,73.26c.58,6-2.27,10.83-5.6,15.45-23.85,55.15-51.79,108-89.65,155-9.24,11.48-19.27,22.33-28.22,34-6.61,8.64-11.15,10.08-19.89,1.36q-125.75-125.45-252.34-250C796.67,762.24,745.18,711,693.13,660.23c-8.41-8.18-9.11-12.4-.27-21.09Q888,447.25,1082.39,254.62c.36-.36.75-.69,1.11-1.05,28-27.69,28-27.72,53.76,3.27q96.72,116.49,129.07,264.4c.76,3.44,2,6.78,3,10.17,6.69,25.36,9.34,51.37,10.45,77.4,1.15,26.84.66,53.78.1,80.67-.3,14.47-2.4,28.89-4.86,43.2l.17-.26c-2,12.31-2,24.94-6.15,36.9l.11-.22C1265.42,773.53,1271.13,780.5,1265.08,784.31Z"
      />
      <path
        className="cls-2"
        style={{ fill: 'url(#linear-gradient-2)' }}
        d="M469.8,1194.16c-12.35-5.21-24.65-10.53-37.05-15.61q-93.72-38.43-187.48-76.8c-4.14-1.7-8.06-3.94-13-6.4,3.33-3.68,5.81-6.71,8.58-9.45Q441.45,887.75,642.05,689.57c6.59-6.51,10.66-11.61,20.29-2.05q202.83,201.32,406.48,401.83c1.43,1.41,2.72,3,4.62,5.06-5.44,6.08-13.07,7.56-19.74,10.3Q932.58,1154.54,811.3,1204c-5.12,2.1-9.9,5-14.84,7.54-5.06,2.91-10.71,4.2-16.18,6-5.62.29-9.93,3.49-14.36,6.43-5,2.73-11.1,3.12-15.33,7.42-10.06,4.11-20.12,8.25-30.73,10.78-14.38,5.69-29.42,10.13-43,17.38-16.91,9-32.06,8-48.66-.48-13.41-6.85-28-11.34-42.12-16.88-16.26-3.67-31.13-10.77-45.73-18.53l.07.06c-11.2-1.8-20.6-8.14-30.91-12.19-5.15-1.13-10.07-2.78-14.13-6.34C486,1203.69,476.64,1201.77,469.8,1194.16Z"
      />
      <path
        className="cls-3"
        style={{ fill: 'url(#linear-gradient-3)' }}
        d="M292.73,945.91c17.14-41.63,34.07-83.35,51.46-124.87q110.34-263.46,220.72-526.9c7.23-17.31,12.62-35.39,21.16-52.19a360,360,0,0,1,19-45.89C625.16,149,645.8,102.12,665,54.69c5.43-13.41,10.19-15.82,24-10.11,128,53,256.29,105.11,384.51,157.48,2.45,6.66-2.13,9.14-6.85,11.52-39.56,35-75.75,73.44-113.36,110.43Q781.13,493.35,609.65,663.41,476.37,795.1,343.11,926.75c-2.86,2.83-5.26,6.1-7.88,9.16-8.61,9.77-18.76,17.88-28.51,26.4-2.85,1.42-6.2,2.25-7,6.09a7.5,7.5,0,0,1-4.57,3.83c-6.68.08-13,.14-7.43-9.6C290.65,957.55,290.63,951.36,292.73,945.91Z"
      />
      <path
        className="cls-4"
        style={{ fill: 'url(#linear-gradient-4)' }}
        d="M561.19,198.71c-21.59,50.85-43.36,101.62-64.74,152.55q-147.6,351.5-295.26,703c-2.16,5.13-1,14.32-10.95,13.84-5.58-5.86-12.11-10.89-16.09-18.16l0,.06c-5.18-2.25-8.06-6.4-9.6-11.65-40.08-50.56-71.82-106-96.92-165.33-6.61-7-8.46-15.71-9.42-24.82a2.17,2.17,0,0,0-.55-2.45c-7.85-11-10.11-23.75-11.53-36.77-6.76-35.35-13.11-70.71-17.78-106.47-4.5-34.46,2.72-59.63,29.39-85.56C238.37,441.4,416.9,263.77,596.06,86.79c3.94-3.89,7.35-8.32,12.21-11.66.22,14.19-8.58,25.14-11.06,38a16.32,16.32,0,0,1-5.94,12.25,30.11,30.11,0,0,0-5.63,14.94c-2.46,5-2.66,11.08-6.63,15.5l.21-.29C573.81,170.16,569.39,185.22,561.19,198.71Z"
      />
    </Svg>
  )
}

export default Icon
